import Swiper from 'swiper/bundle';

export function initsSliderCarouselWhy() {
    const swiper = new Swiper('[data-swiper-why]', {
        direction: 'horizontal',
        centeredSlides: true,
        slidesPerView: 'auto',
        loop: true,
        pagination: {
            el: '[data-why-pagination]',
        },
        navigation: {
            nextEl: '[data-why-btn-prev]',
            prevEl: '[data-why-btn-next]',
        },
    });
}
