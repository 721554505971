import anime from 'animejs/lib/anime.es.js';

export const initAccordion = () => {
    // VARS
    const toggles = document.querySelectorAll('[data-accordion-toggle]');
    if (!toggles.length) return;
    let duration = 300;
    const activeClass = 'is-open-accordion';
    const activeBtn = '--active-table-arrow';

    // EVENTS
    toggles.forEach((toggle) => {
        toggle.addEventListener('click', handleOnClick, false);
    });

    document.addEventListener('keydown', function (event) {
        if (event.key === 'Enter' && document.activeElement.closest('[data-accordion-toggle]')) {
            handleOnClick(document.activeElement);
        }
    });

    // HANDLERS
    function handleOnClick(activeElement) {
        const accordionElement = this || activeElement;
        const accordion = accordionElement.closest('[data-accordion]');

        accordion.classList.contains(activeClass) ? closeAccordion(accordion) : openAccordion(accordion);
    }

    // FUNCTIONS
    function openAccordion(accordion) {
        const body = accordion.querySelector('[data-accordion-body]');
        const btn = accordion.querySelector('[btn-table-arrow]');
        const height = body.scrollHeight;
        accordion.classList.add(activeClass);

        if (btn) {
            btn.classList.add(activeBtn);
        }

        anime({
            targets: body,
            height: [0, height],
            easing: 'linear',
            duration: duration,
            complete: function () {
                body.style.height = 'auto';
            },
        });
    }

    function closeAccordion(accordion) {
        const body = accordion.querySelector('[data-accordion-body]');
        const btn = accordion.querySelector('[btn-table-arrow]');
        const height = body.scrollHeight;
        body.style.height = `${height}px`;
        accordion.classList.remove(activeClass);

        if (btn) {
            btn.classList.remove(activeBtn);
        }

        anime({
            targets: body,
            height: 0,
            easing: 'linear',
            duration: duration,
        });
    }
};
