import { initsSliderCarouselWhy } from './page/initsSliderCarouselWhy';
import { initAccordion } from './page/initAccordion';
import { initSubscribeFollow } from './page/initSubscribeFollow';
import { initStopAnimateOnResize } from '../../../../Common/Resources/src_front/js/components/initStopAnimateOnResize';
import { initFunnelParams } from './page/components/helper-functions/initFunnelParams';

document.addEventListener('DOMContentLoaded', handleOnLoadPage);

function handleOnLoadPage() {
    initsSliderCarouselWhy();
    initAccordion();
    initSubscribeFollow();
    initStopAnimateOnResize();
    initFunnelParams();
}
