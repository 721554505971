import { isValidMail } from '../../../../../Common/Resources/src_front/js/helper-functions/isValidMail';

export function initSubscribeFollow() {
    const emailInput = document.querySelector('[data-input-email]');
    const form = document.querySelector('[data-email-form]');
    const errorText = document.querySelector('[data-error]');
    const errorClassActive = 'error-validation';
    let search = window.location.search;

    form.addEventListener('submit', handleOnSubmit, false);

    function handleOnSubmit(event) {
        event.preventDefault();

        const email = `${emailInput.value.trim()}`;

        search === ''
            ? (search = `?email=${encodeURIComponent(email)}`)
            : `${search}&email=${encodeURIComponent(email)}`;

        if (isValidMail(email)) {
            window.location.href = `https://umobix.com/${search}`;
            errorText.classList.remove(errorClassActive);
        } else {
            errorText.classList.add(errorClassActive);
        }
    }
}
