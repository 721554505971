export function initFunnelParams() {
    // VARS
    const links = document.querySelectorAll('a');
    if (!links.length) return;
    const search = window.location.search;

    // INITS
    links.forEach((link) => {
        link.href = `${link.href}${search}`;
    });
}
